import React, { useCallback, useState } from 'react';
import { CDN_MARKETPLACE } from '../scene/assets/baseUrl';
import { useWindowSize } from '../hooks/useWindowDimensions';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Typography,
  Flex,
  Form,
  Input,
  Button,
  Collapse,
  Spin,
  Result,
  Alert,
} from 'antd';
import { PRIZES } from './data/prizes';
import { DEFAULT_ERROR } from 'util/constants';
import { openNewTab } from 'util/helpers';
import styles from './styles.module.css';

const EMAIL_ALREADY_EXISTS = "You've already subscribed";
const SUCCESS = 'We successfully registered your email!';

export default function ZepetoLanding() {
  const size = useWindowSize();
  const [isRegistered, setIsRegistred] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorType, setErrorType] = useState('error' as 'error' | 'warning');

  function openBuilder() {
    openNewTab('/new-template');
  }

  const onFinish = useCallback((values) => {
    setLoading(true);
    setError('');
    const email = values.email;
    const origin = window.location.origin;

    fetch &&
      fetch(`/api/mail/subscribe/?email=${email}&origin=${origin}`, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);

          if (!result.status) {
            setError(DEFAULT_ERROR);
            setErrorType('error');
            return;
          }

          if (result?.message === EMAIL_ALREADY_EXISTS) {
            setError(EMAIL_ALREADY_EXISTS);
            setErrorType('warning');
            return;
          }

          setIsRegistred(true);
        })
        .catch((e) => {
          setError(DEFAULT_ERROR);
          setErrorType('error');
          setLoading(false);
        });
  }, []);

  return (
    <main className={styles.main}>
      <Flex
        className={styles.topSection}
        vertical
        align='center'
        justify='center'
      >
        <div className={styles.topSectionBg}>
          <img
            src={`${CDN_MARKETPLACE}/ozone-website-public/zepeto-landing-bg.jpg`}
            alt='Zepeto announce banner'
            className={styles.bgImg}
          />
        </div>
        <Typography.Title
          level={1}
          className={styles.title}
          style={{ marginBottom: 10 }}
        >
          ZEPETOWEB.COM
        </Typography.Title>
        <Typography.Title
          level={2}
          className={styles.subtitle}
          style={{ marginTop: 0, fontWeight: 600 }}
        >
          A partnership between Zepeto & OZONE
        </Typography.Title>
        <Typography.Title level={3} className={styles.subtitle}>
          Build on Web - Save as Unity
        </Typography.Title>
        <Button
          type='primary'
          className={styles.launchButton}
          onClick={openBuilder}
        >
          Launch Zepetoweb Builder
        </Button>
      </Flex>
      <Flex
        className={styles.promoSection}
        vertical
        align='center'
        justify='center'
      >
        <Typography.Title level={1} className={styles.title}>
          LAUNCH PROMO
        </Typography.Title>
        {isRegistered ? (
          <Result status='success' title={SUCCESS} />
        ) : (
          <>
            <Typography.Paragraph className={styles.promoSubtitle}>
              Enter your email to join the soft launch and receive special
              updates and perks
            </Typography.Paragraph>
            <Form
              layout='horizontal'
              className={styles.form}
              onFinish={onFinish}
            >
              <Form.Item
                name='email'
                hasFeedback
                rules={[
                  {
                    type: 'email',
                    message: (
                      <span className={styles.errorMessage}>
                        The input is not valid email
                      </span>
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <span className={styles.errorMessage}>
                        Please input your email
                      </span>
                    ),
                  },
                ]}
              >
                <Input
                  placeholder='Enter your email'
                  className={styles.input}
                />
              </Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className={styles.formButton}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, color: '#fff' }}
                        spin
                      />
                    }
                  />
                ) : (
                  'Subscribe now'
                )}
              </Button>
            </Form>
            {error && (
              <Alert message={error} type={errorType} showIcon closable />
            )}
          </>
        )}
      </Flex>
      <Flex wrap='wrap' gap={20} justify='center'>
        {PRIZES.map((card, i) => {
          return (
            <div className={styles.card} key={i}>
              <Typography.Title style={{ marginTop: 0 }} level={1}>
                {card.title}
              </Typography.Title>
              <Typography.Paragraph className={styles.cardDesc}>
                {card.desc}
              </Typography.Paragraph>
              <Flex vertical gap={12}>
                {card.items.map((prize, i) => {
                  return (
                    <Flex key={i} className={styles.prize} gap={8}>
                      <span style={{ color: prize.color, fontWeight: 600 }}>
                        {prize.count}
                      </span>
                      <span className={styles.prizeText}>{prize.text}</span>
                    </Flex>
                  );
                })}
              </Flex>
              <img
                src={`${CDN_MARKETPLACE}/ozone-website-public/${card.img}`}
                alt='card prize chest'
                className={styles.prizeImg}
              />
            </div>
          );
        })}
      </Flex>
      <Flex className={styles.airdropSection}>
        <img
          src={
            size.width! > 980
              ? `${CDN_MARKETPLACE}/ozone-website-public/airdrop-bg-zepeto.jpg`
              : `${CDN_MARKETPLACE}/ozone-website-public/airdrop-bg-zepeto-mobile.jpg`
          }
          className={styles.airdropImg}
          alt='Zepeto airdrop date banner'
        />
        <Typography.Title level={2} className={styles.airdropText}>
          Prizes wil be delivered by airdrop to your account before end of
          August 2024
        </Typography.Title>
      </Flex>
      <section className={styles.studySection}>
        <Collapse
          collapsible='header'
          items={[
            {
              key: '1',
              label: (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Case Study
                </Typography.Title>
              ),
              children: (
                <Flex gap={48} className={styles.studyContent}>
                  <div>
                    <Typography.Paragraph className={styles.paragraph}>
                      Zepeto is one of the world's most vibrant metaverse
                      platforms out of Asia. Started in Korea, Zepeto boasts
                      <span className={styles.studyHighlight}>
                        {' '}
                        300 million registered users
                      </span>{' '}
                      and 100 million monthly active users
                    </Typography.Paragraph>
                    <Typography.Paragraph className={styles.paragraph}>
                      <span className={styles.studyHighlight}>
                        The Problem:
                      </span>{' '}
                      Based on Unity game engine, it is very hard for users to
                      build worlds, always needing to download assets and
                      compose their environments in Unity.
                    </Typography.Paragraph>
                    <Typography.Paragraph className={styles.paragraph}>
                      <span className={styles.studyHighlight}>
                        The Solution:
                      </span>{' '}
                      OZONE built a web builder with endless access to 3D models
                      and assets store, and enables users to easily choose from
                      a wide selection of single assets or prefabricated
                      templates to build their own unique original worlds. Build
                      in minutes on the web and save as a unity project, ready
                      to be deployed into the Zepeto App.
                    </Typography.Paragraph>
                  </div>
                  <Flex vertical align='center'>
                    <img
                      src={`${CDN_MARKETPLACE}/ozone-website-public/zepeto-logo.png`}
                      alt='Zepeto'
                      width={150}
                    />
                    X
                    <img
                      src={`${CDN_MARKETPLACE}/ozone-website-public/ozone-tech.png`}
                      alt='Ozone technologies'
                      width={230}
                    />
                  </Flex>
                </Flex>
              ),
            },
          ]}
        />
      </section>
    </main>
  );
}
