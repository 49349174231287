const BLUE = 'rgba(45, 123, 239, 1)';
const PINK = 'rgba(224, 33, 137, 1)';

export const PRIZES = [
  {
    title: 'Prize 1',
    desc: 'The first 100 sign-ups get for free:',
    items: [
      { count: 5, text: 'single paid assets', color: PINK },
      {
        count: 1,
        text: 'paid prefab asset',
        color: BLUE,
      },
    ],
    img: 'chest-1.png',
  },
  {
    title: 'Prize 2',
    desc: 'The first 50 world builders on Zepeto Web receive for free:',
    items: [
      { count: 5, text: 'single paid assets', color: PINK },
      { count: 3, text: 'paid prefabs', color: BLUE },
    ],
    img: 'chest-2.png',
  },
  {
    title: 'Prize 3',
    desc: 'The first 10 world builders to deliver their world from Zepeto Web to the Zepeto app will receive:',
    items: [
      { count: 10, text: 'single paid assets', color: PINK },
      { count: 5, text: 'paid prefab assets', color: PINK },
      {
        count: 1,
        text: 'Exclusive limited Edition NFT world',
        color: BLUE,
      },
    ],
    img: 'chest-3.png',
  },
];
